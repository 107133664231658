.instruction:nth-of-type(2n) {
  background-color: #f8f8f8;
}

.instructions-table {
  font-size: 12px;
  table td,
  table th {
    padding: 3px;
  }
  table th:nth-of-type(3),
  table th:nth-of-type(4),
  table td:nth-of-type(3),
  table td:nth-of-type(4) {
    width: 50px;
  }
}
