.delete-btn {
  padding: 2px !important;
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

.h4 {
  height: 0px !important;
}

.image-box {
  right: 20px;
  top: 20px;
  &.loading {
    img {
      opacity: 0.3;
    }
  }
  &.success .image {
    border-color: lawngreen;
  }
  &.error .image {
    border-color: red;
  }
}

.image {
  border-radius: 4px;
  border: 6px solid white;
}

.loading-box {
  margin-top: 20px;
  margin-left: 20px;
}

.form-control {
  background-color: #333 !important;
  color: white !important;
  border: 1px solid #888 !important;
}
