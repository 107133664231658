.top-tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border-style: solid;
  border-color: #9caeff;
  margin-bottom: 10px;
  .tab {
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #555;
    color: white;
    text-align: center;
    padding: 8px;
    cursor: pointer;

    &:hover {
      background-color: #777;
    }
    &.selected {
      background: linear-gradient(346deg, rgb(34, 100, 197) 0%, rgb(103, 97, 216) 100%);
    }
  }
}

.root {
  min-height: 800px;
  min-width: 1000px;
}

.sidebar {
  background-color: #333;
}
