.data-list {
  height: 60vh;
  border: 2px;
  border-radius: 4px;
  border-style: solid;
  border-color: #9caeff;
  color: #ddd;
  margin-bottom: 10px;
  overflow: auto;
  font-size: 14px;
  .rating {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: black;
  }
}

.action-buttons {
  flex-direction: row;
  margin-top: 2px;
}

.delete-button {
  width: fit-content;
  padding: 0 2px 0 2px;
  border-radius: 2px;
  background-color: #c9c9c9;
  &:hover {
    background-color: red;
  }
}

.button-text {
  text-align: center;
  color: black;
}

.list-item {
  padding: 8px;
  cursor: pointer;
  background-color: #555;
  &.selected {
    background-color: #4942d4 !important;
  }
  &:nth-of-type(2n) {
    background-color: #444;
  }
  &:hover {
    background-color: #5a5a5a;
  }
  &.video {
    background-color: #0f4e57;
  }
  .spinner-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tile-interior{
  display: flex;
  flex-direction: row;
  }

}
