.nav {
  margin-bottom: 20px !important;
}

.dropdown-toggle {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.dl-button-box {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.delete-btn {
  padding: 2px !important;
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

.h4 {
  height: 0px !important;
}

.datalogger-action-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  margin-top: 2px;
  cursor: default;
}

.delete-button {
  width: fit-content;
  padding: 0 2px 0 2px;
  border-radius: 2px;
  background-color: #c9c9c9;
  &:hover {
    background-color: red;
  }
}

.button-text {
  text-align: center;
  color: black;
}

.image-box {
  right: 20px;
  top: 20px;
  &.loading {
    img {
      opacity: 0.3;
    }
  }
  &.success .image {
    border-color: lawngreen;
  }
  &.error .image {
    border-color: red;
  }
}

.image {
  border-radius: 4px;
  border: 6px solid white;
}

.loading-box {
  margin-top: 20px;
  margin-left: 20px;
}
