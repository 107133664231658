body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.el-tabs__content {
  overflow: visible !important;
}

.el-button + .el-button {
  margin-left: 0 !important;
}

.extra-options {
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #9caeff;
  background-color: #555;
  padding: 8px;
  font-size: 14px;
  color: #ddd;
}

.modal-content {
  background-color: #555 !important;
  color: #ddd;
  box-shadow: rgba(30, 30, 30, 0.3) 0px 3px 10px 0px;
  button.close {
    text-shadow: 0 1px 0 #aaa;
  }
  .modal-body p {
    margin-bottom: 0;
  }
  .modal-header {
    border-bottom-color: #222;
  }
  .modal-footer {
    border-top-color: #222;
  }
}

.nav {
  margin-bottom: 20px !important;
}

.dropdown-toggle {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.dropdown-menu {
  height: 200px !important;
  width: 360px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  margin-left: 2px !important;
  background-color: #555 !important;
  border: 1px solid #888 !important;
  box-shadow: #333 0px 3px 10px 0px;
  a {
    color: #eee !important;
    padding: 3px 10px;
    &:hover {
      background-color: #666 !important;
    }
  }
}
