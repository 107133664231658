.screen-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(81, 72, 232);
  background: linear-gradient(
    346deg,
    rgba(81, 72, 232, 1) 0%,
    rgba(57, 57, 177, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  img {
    height: 200px;
    margin-top: -60px;
  }
  .environment,
  .versions {
    font-size: 22px;
    font-weight: bold;
    color: white;
    text-align: center;
    line-height: normal;
    font-family: 'Courier New', Courier, monospace;
    margin-top: 20px;
  }
  .versions {
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
    font-family: 'Courier New', Courier, monospace;
  }
}

.form-container {
  width: 800px;
  padding: 40px;
  border-radius: 8px;
  background-color: #222;
  color: #ddd;
  border: 3px solid #9caeff;
}
