.search-filter-container {
  margin-bottom: 10px;
  background-color: #555;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 1px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 123, 255, 0);
  &:hover {
    border-color: rgba(0, 123, 255, 0.5);
  }
  &.toggled {
    border-color: rgb(0, 123, 255);
  }
}

.filter-icon {
  width: 30px;
  transform: rotate(90deg);
}

.card {
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #9caeff !important;
  background-color: #555 !important;
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px !important;
  background-color: #555 !important;
}

.search-card {
  background-color: #555;
}
