.map-container {
  position: relative;
  flex: 1;
}

.map-viewer {
  flex: 1;
  width: 100%;
  height: 100%;
}

.coordinates {
  top: 5px;
  left: 5px;
  font-family: 'Courier New';
  text-align: justify;
}

.map-buttons {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
  .map-button {
    cursor: pointer;
    background-color: #333;
    border: 2px solid #111;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    color: #ddd;
    &:hover {
      background-color: #777;
    }
  }
}

.directions-text-container {
  position: fixed;
  left: 410px;
  top: 300px;
  width: 300px;

  .direction-text {
    font-size: 20px;
    text-align: right;
    background-color: #333;
    border-radius: 5px;
    border: 3px solid #111;
    padding: 5px 10px;
    margin-bottom: 5px;
    color: #eee;
  }

  #directionTextActual {
    color: red;
  }
}

.extra-map-info {
  position: fixed;
  left: 410px;
  bottom: 10px;
  width: 300px;
  border-color: #111;
  background-color: #333;
}
